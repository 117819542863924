<template>
    <Toast></Toast>
    <div class="grid">
        <div class="col-12">

            <div class="col-12 xl:col-12">
                <div class="card">
                    <h5 class="m-0">Liberar Pagamentos</h5>
                </div>
            </div>


            <div class="card">
                <div class="grid">
                    <div class="col-12">
                        <div class="field m-0">
                            <label for="id_fornecedor">Filtrar por Fornecedor/Mês/Ano</label><br>
                            <Dropdown filter id="id_fornecedor" required="true" v-model="id_fornecedor"
                                :options="fornecedores" optionLabel="label" optionValue="value"
                                placeholder="Selecione um Fornecedor">
                            </Dropdown>

                            <Dropdown filter id="mes" required="true" v-model="mes" :options="meses" class="ml-3"
                                optionLabel="label" optionValue="value" placeholder="Selecione um Mês">
                            </Dropdown>
                            <Dropdown filter id="ano" required="true" v-model="ano" :options="anos" class="ml-3"
                                optionLabel="label" optionValue="value" placeholder="Selecione um Ano">
                            </Dropdown>
                            <Button class="ml-3" @click="listarRelatorioPorFornecedor()">Filtrar</Button>
                        </div>

                    </div>
                </div>

            </div>
            <div>
                <div class="card">

                    <div class="grid mb-2">

                        <div class="col-8">
                            <h5>Planilhas Importadas por Fornecedores</h5>
                        </div>
                        <div class="col-4" style="text-align: center;">
                            <Button :disabled="selecionados" class="ml-3 p-button-success"
                                @click="aprovarSelecionados()">Aprovar Selecionados</Button>
                            <Button :disabled="selecionados" class="ml-3 p-button-danger"
                                @click="reprovarSelecionados()">Reprovar Selecionados</Button>
                        </div>
                    </div>

                    <DataTable v-model:filters="filters" :loading="loading" :value="planilhas_por_fornecedores" :rows="10"
                        stripedRows scrollable scrollHeight="400px" :paginator="true" responsiveLayout="scroll"
                        v-model:selection="planilhasSelecionadas"
                        :globalFilterFields="['razao_social', 'data_upload', 'status', 'mes_referencia']">
                        <template #header>
                            <div class="flex justify-content-end">
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search" />
                                    <InputText v-model="filters['global'].value" placeholder="Buscar" />
                                </span>
                            </div>
                        </template>
                        <Column selectionMode="multiple" headerStyle="width: 3rem" style="min-width: 150px"></Column>
                        <Column style="min-width: 450px" field="razao_social" header="Fornecedor" :sortable="true">



                        </Column>
                        <Column style="min-width: 200px" field="mes_referencia" header="Mês Referência" :sortable="true">
                        </Column>

                        <Column style="min-width: 200px" field="data_upload" header="Data Upload" :sortable="true"></Column>

                        <Column style="min-width: 200px" field="id" header="Número do Lote" :sortable="true">
                        </Column>
                        <Column style="min-width: 200px" field="status" header="Status do Processo" :sortable="true">
                        </Column>
                        <Column style="min-width: 200px" field="responsavel" header="Responsável" :sortable="true">
                        </Column>


                        <Column style="min-width: 300px" field="qtd_endid_enviado" header="Quantidade Endereço ID  Enviado"
                            :sortable="true">
                        </Column>

                        <Column style="min-width: 300px" field="qtd_endid_aprovado"
                            header="Quantidade Endereço ID  Aprovado" :sortable="true">
                        </Column>

                        <Column style="min-width: 300px" field="valor_endid_aprovado" header="Valor Endereço ID Aprovado"
                            :sortable="true">
                        </Column>

                        <Column style="min-width: 300px" field="qtd_endid_reprovado"
                            header="Quantidade Endereço ID Reprovados" :sortable="true">
                        </Column>

                        <Column style="min-width: 300px" field="valor_endid_reprovado" header="Valor  Endereço ID Reprovado"
                            :sortable="true">
                        </Column>

                        <Column frozen style="white-space: nowrap;" alignFrozen="right">

                            <template #body="slotProps">
                                <Button @click="mostrarPagamento(slotProps.data, 1)" type="button"
                                    class="p-button-primary p-sm">Gerenciar</Button>
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </div>

        </div>
    </div>
    <Dialog v-model:visible="ModalPlanilhasFornecedores" :style="{ width: '80%' }"
        :header="fornecedor + ' - ' + mes_referencia" :modal="true">



        <!-- <div class="grid">
            <div class="col-12 lg:col-6 xl:col-3">
                <div class="card mb-0">
                    <div class="flex justify-content-between ">
                        <div>
                            <span class="block text-500 font-medium mb-3">Planilhas Importadas</span>
                            <div class="text-900 font-medium text-xl">{{ planilhas_importadas }}</div>
                        </div>
                        <div class="
              flex
              align-items-center
              justify-content-center
              bg-blue-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
                            <i class="pi pi-cloud-upload text-blue-500 text-xl"></i>
                        </div>
                    </div>
                    <span class="text-green-500 font-medium"></span>
                </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-3">
                <div class="card mb-0">
                    <div class="flex justify-content-between ">
                        <div>
                            <span class="block text-500 font-medium mb-3">Planilhas para Validar</span>
                            <div class="text-900 font-medium text-xl">{{ planilhas_validar }}</div>
                        </div>
                        <div class="
              flex
              align-items-center
              justify-content-center
              bg-orange-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
                            <i class="pi pi-info-circle text-orange-500 text-xl"></i>
                        </div>
                    </div>
                    <span class="text-green-500 font-medium"></span>
                </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-3">
                <div class="card mb-0">
                    <div class="flex justify-content-between ">
                        <div>
                            <span class="block text-500 font-medium mb-3">Planilhas Validadas</span>
                            <div class="text-900 font-medium text-xl">{{ planilhas_validadas }}</div>
                        </div>
                        <div class="
              flex
              align-items-center
              justify-content-center
              bg-cyan-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
                            <i class="pi pi-check-circle text-cyan-500 text-xl"></i>
                        </div>
                    </div>
                    <span class="text-green-500 font-medium"> </span>
                </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-3">
                <div class="card mb-0">
                    <div class="flex justify-content-between ">
                        <div>
                            <span class="block text-500 font-medium mb-3">Planilhas Reprovadas</span>
                            <div class="text-900 font-medium text-xl">{{ planilhas_reprovadas }}</div>
                        </div>
                        <div class="
              flex
              align-items-center
              justify-content-center
              bg-red-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
                            <i class="pi pi-exclamation-triangle text-red-500 text-xl"></i>
                        </div>
                    </div>
                    <span class="text-green-500 font-medium"></span>
                </div>
            </div>
        </div> -->


        <div class="grid">
            <div class="col-12 lg:col-6 xl:col-2">
                <div class="card mb-0">
                    <div class="flex justify-content-between ">
                        <div>
                            <span class="block text-500 font-medium mb-3">Total End ID</span>
                            <div class="text-900 font-medium text-xl">{{ total_end_id }}</div>
                        </div>
                        <div class="
              flex
              align-items-center
              justify-content-center
              bg-blue-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
                            <i class="pi pi-cloud-upload text-blue-500 text-xl"></i>
                        </div>
                    </div>
                    <span class="text-green-500 font-medium"></span>
                </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-2">
                <div class="card mb-0">
                    <div class="flex justify-content-between ">
                        <div>
                            <span class="block text-500 font-medium mb-3">End ID Validado</span>
                            <div class="text-900 font-medium text-xl">{{ end_id_validado }}</div>
                        </div>
                        <div class="
              flex
              align-items-center
              justify-content-center
              bg-green-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
                            <i class="pi pi-check text-green-500 text-xl"></i>
                        </div>
                    </div>
                    <span class="text-green-500 font-medium"></span>
                </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-2">
                <div class="card mb-0">
                    <div class="flex justify-content-between ">
                        <div>
                            <span class="block text-500 font-medium mb-3">End ID Contestado</span>
                            <div class="text-900 font-medium text-xl">{{ end_id_contestado }}</div>
                        </div>
                        <div class="
              flex
              align-items-center
              justify-content-center
              bg-orange-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
                            <i class="pi pi-exclamation-triangle text-orange-500 text-xl"></i>
                        </div>
                    </div>
                    <span class="text-orange-500 font-medium"> </span>
                </div>
            </div>


            <div class="col-12 lg:col-6 xl:col-2">
                <div class="card mb-0">
                    <div class="flex justify-content-between ">
                        <div>
                            <span class="block text-500 font-medium mb-3">Valor Solicitado</span>
                            <div class="text-900 font-medium text-xl">{{ valor_total }}</div>
                        </div>
                        <div class="
              flex
              align-items-center
              justify-content-center
              bg-blue-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
                            <i class="pi pi-dollar text-blue-500 text-xl"></i>
                        </div>
                    </div>
                    <span class="text-blue-500 font-medium"></span>
                </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-2">
                <div class="card mb-0">
                    <div class="flex justify-content-between ">
                        <div>
                            <span class="block text-500 font-medium mb-3">Valor Contestado</span>
                            <div class="text-900 font-medium text-xl">{{ valor_contestado }}</div>
                        </div>
                        <div class="
              flex
              align-items-center
              justify-content-center
              bg-red-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
                            <i class="pi pi-dollar text-red-500 text-xl"></i>
                        </div>
                    </div>
                    <span class="text-green-500 font-medium"></span>
                </div>
            </div>
            <div class="col-12 lg:col-6 xl:col-2">
                <div class="card mb-0">
                    <div class="flex justify-content-between ">
                        <div>
                            <span class="block text-500 font-medium mb-3">Valor Validado</span>
                            <div class="text-900 font-medium text-xl">{{ valor_validado }}</div>
                        </div>
                        <div class="
              flex
              align-items-center
              justify-content-center
              bg-green-100
              border-round
            " style="width: 2.5rem; height: 2.5rem">
                            <i class="pi pi-dollar text-green-500 text-xl"></i>
                        </div>
                    </div>
                    <span class="text-green-500 font-medium"></span>
                </div>
            </div>
        </div>





        <hr />
        <div class="card">
            <div class="grid">
                <div class="col-8 bg-blue-100">
                    <b>Motivos da Contestação</b>
                </div>
                <div class="col-2 bg-blue-100">
                    <b>Total</b>
                </div>
                <div class="col-2 bg-blue-100">
                    <b>Valor Contestado (R$)</b>
                </div>
                <div class="col-8" v-if="total_medidor_proprio_tim>0">Medidor Próprio TIM</div>
                <div class="col-2" v-if="total_medidor_proprio_tim>0"> {{ total_medidor_proprio_tim }}</div>
                <div class="col-2" v-if="total_medidor_proprio_tim>0"> {{ medidor_proprio_tim }}</div>

                <div class="col-8" v-if="total_site_em_aquisicao>0">Site em aquisição ou Obra</div>
                <div class="col-2" v-if="total_site_em_aquisicao>0"> {{ total_site_em_aquisicao }}</div>
                <div class="col-2" v-if="total_site_em_aquisicao>0"> {{  site_em_aquisicao }}</div>

                <div class="col-8" v-if="total_mes_ref_pago>0">Mês de ref. Já Pago</div>
                <div class="col-2" v-if="total_mes_ref_pago>0"> {{ total_mes_ref_pago }}</div>
                <div class="col-2" v-if="total_mes_ref_pago>0"> {{ mes_ref_pago }}</div>

                <div class="col-8" v-if="total_site_nao_localizado>0">Site Não Localizado, inativo ou desmobilizado</div>
                <div class="col-2" v-if="total_site_nao_localizado>0"> {{ total_site_nao_localizado }}</div>
                <div class="col-2" v-if="total_site_nao_localizado>0"> {{ site_nao_localizado }}</div>

                <div class="col-8" v-if="total_site_offgrid>0">Site OffGrid</div>
                <div class="col-2" v-if="total_site_offgrid>0"> {{ total_site_offgrid }}</div>
                <div class="col-2" v-if="total_site_offgrid>0"> {{ site_offgrid }}</div>

                <div class="col-8" v-if="total_ausencia_comprovante>0" >Ausência do Comprovante de Pagamento</div>
                <div class="col-2" v-if="total_ausencia_comprovante>0"> {{ total_ausencia_comprovante }}</div>
                <div class="col-2" v-if="total_ausencia_comprovante>0"> {{ ausencia_comprovante }}</div>

                <div class="col-8" v-if="total_sci_exclusao>0">SCI de Exclusão Aprovado</div>
                <div class="col-2" v-if="total_sci_exclusao>0"> {{ total_sci_exclusao }}</div>
                <div class="col-2" v-if="total_sci_exclusao>0"> {{ sci_exclusao }}</div>

                <div class="col-8" v-if="total_reembolso_fornecedor>0">Reembolso para outro Fornecedor</div>
                <div class="col-2" v-if="total_reembolso_fornecedor>0"> {{ total_reembolso_fornecedor }}</div>
                <div class="col-2" v-if="total_reembolso_fornecedor>0"> {{ reembolso_fornecedor }}</div>

                <div class="col-8"  v-if="total_consumo_acima_esperado>0">Consumo acima do esperado</div>
                <div class="col-2"  v-if="total_consumo_acima_esperado>0"> {{ total_consumo_acima_esperado }}</div>
                <div class="col-2"  v-if="total_consumo_acima_esperado>0"> {{ consumo_acima_esperado }}</div>


                <div class="col-8 bg-gray-100" v-if="total_outros>0">Outros</div>
                <div class="col-2 bg-gray-100" v-if="total_outros>0"> {{ total_outros }}</div>
                <div class="col-2 bg-gray-100" v-if="total_outros>0"> {{ outros }}</div>

                <div class="col-8 bg-blue-100">
                    <b>Total Geral</b>
                </div>
                <div class="col-2 bg-blue-100">

                </div>
                <div class="col-2 bg-blue-100">
                    <b> {{ valor_total_contestado }}</b>
                </div>
            </div>
        </div>
        <hr />
        <div class="card" style="overflow: hidden;">
            <Button :disabled="temdados" :loading="loading" label="Aprovar Pagamento" @click="aprovarPagamento()"
                class="p-button-success" style="float: left;" />

            <Button :disabled="temdados" :loading="loading" label="Reprovar Pagamento" @click="this.Modalreprovar = true"
                class="p-button-danger" style="float: right;" />
        </div>
    </Dialog>

    <Dialog v-model:visible="Modalreprovar" :style="{ width: '50%' }" :header="fornecedor + ' - ' + mes_referencia"
        :modal="true">
        <label for="id_fornecedor">Motivo</label><br>
        <Textarea v-model="motivo" rows="6" style="width:99%; margin:20px 0 ;"></Textarea>
        <Button :disabled="temdados" :loading="loading" label="Reprovar Pagamento" @click="reprovarPagamento()"
            class="p-button-danger" style="float: right;" />
    </Dialog>
</template>
  
<script>
import { FilterMatchMode } from "primevue/api";

export default {
    data() {
        return {
            Modalreprovar: false,
            id_planilha: "",
            motivo: "",
            ModalPlanilhasFornecedores: false,
            planilhas_por_fornecedores: [],
            temdados: true,
            mes_referencia: "",
            loading: false,
            total_end_id: 0,
            end_id_validado: 0,
            end_id_contestado: 0,
            valor_validado: 0,
            valor_contestado: 0,
            valor_total: 0,
            planilhas_importadas: 0,
            planilhas_validar: 0,
            planilhas_validadas: 0,
            planilhas_reprovadas: 0,
            medidor_proprio_tim: 0,
            site_em_aquisicao: 0,
            selecionados: true,
            outros: 0,
            valor_total_contestado: 0,
            anos: [
                { label: "", value: "" },
                { label: "2021", value: "2021" },
                { label: "2022", value: "2022" },
                { label: "2023", value: "2023" }],
            meses: [
                { label: "", value: "" },
                { label: "Janeiro", value: "01" },
                { label: "Fevereiro", value: "02" },
                { label: "Março", value: "03" },
                { label: "Abril", value: "04" },
                { label: "Maio", value: "05" },
                { label: "Junho", value: "06" },
                { label: "Julho", value: "07" },
                { label: "Agosto", value: "08" },
                { label: "Setembro", value: "09" },
                { label: "Outubro", value: "10" },
                { label: "Novembro", value: "11" },
                { label: "Dezembro", value: "12" },
            ],
            mostrarPlanilhaModal: false,
            planilhas: [],
            cabecalho: [
                { header: "Site ID TIM", field: 'site_id_tim' },
                { header: "END ID TIM", field: 'endereco_id_tim' },
                { header: "Regional TIM", field: 'regional_tim' },
                { header: "UF", field: 'uf' },
                { header: "Detentora ", field: 'detentora' },
                { header: "Site ID Detentora", field: 'site_id_detentora' },
                { header: "Data RFI", field: 'data_rfi' },
                { header: "Tipo de Energia", field: 'tipo_de_energia' },
                { header: "Consumo (KWh)", field: 'consumo_kWh' },
                { header: "É viável Instalação de Medidor TIM?", field: 'e_viavel_instalacao_de_medidor_tim' },
                { header: "Justificativa", field: 'justificativa' },
                { header: "Mês de Referência do Formulário", field: 'mes_de_referencia_do_formulario' },
                { header: "Data de envio do formulário", field: 'data_de_envio_do_formulario' },
                { header: "Mês de Referência da Fatura", field: 'mes_de_Referencia_da_fatura' },
                { header: "Valor Total Fatura (R$) ", field: 'valor_total_fatura' },
                { header: "Concatenado (END_ID + Dententora)", field: 'concatenado' },

                //Base Xerox
                { header: "Status Base Cadastral", field: 'status_base_cadastral' },
                { header: "Status SGE", field: 'status_sge' },
                { header: "Data Entrante SGE", field: 'data_entrante_sge' },
                { header: "Status Titularidade", field: 'status_titularidade' },
                { header: "Status Site OPM", field: 'status_site_opm' },
                { header: "ETAPA ATUAL NETFLOW", field: 'etapa_atual_netflow' },

                //regras
                { header: "Classificação do Reembolso", field: 'classificacao_do_reembolso' },
                { header: "Motivo da Contestação", field: 'motivo_da_contestacao' },
                { header: "Valor Contestado (R$)", field: 'valor_contestado' },
                { header: "Valor Validado (R$)", field: 'valor_validado' },
                { header: "Status", field: 'status' },
                { header: "Data da validação", field: 'data_validacao' },
                { header: "Link", field: 'link' },
            ],
            motivos_contestacao: [
                { label: "", value: "" },
                { label: "Medidor Próprio TIM", value: "Medidor Próprio TIM" },
                { label: "Site em aquisição ou Obra", value: "Site em aquisição ou Obra" },
                { label: "Mês de ref. Já Pago", value: "Mês de ref. Já Pago" },
                { label: "Site Não Localizado, inativo ou desmobilizado", value: "Site Não Localizado, inativo ou desmobilizado" },
                { label: "Site OffGrid", value: "Site OffGrid" },
                { label: "Ausência do Comprovante de Pagamento", value: "Ausência do Comprovante de Pagamento" },
                { label: "SCI de Exclusão Aprovado", value: "SCI de Exclusão Aprovado" },
                { label: "Reembolso para outro Fornecedor", value: "Reembolso para outro Fornecedor" },
                { label: "Consumo acima do esperado", value: "Consumo acima do esperado" },
                { label: "Outros", value: "Outros" },
            ],
            empresas: [],
            productDialog: false,
            deleteProductDialog: false,
            deleteProductsDialog: false,
            empresa: {},
            planilhasSelecionadas: null,
            fornecedores: [],
            id_fornecedor: 0,
            fornecedor: "",
            mes: "",
            ano: "",
            filters: {},
            submitted: false,
            status: 0,
            total_medidor_proprio_tim: 0,
            total_site_em_aquisicao: 0,
            total_outros: 0,
            total_mes_ref_pago: 0,
            mes_ref_pago: 0,
            site_nao_localizado: 0,
            total_site_nao_localizado: 0,
            site_offgrid: 0,
            total_site_offgrid: 0,
            ausencia_comprovante: 0,
            total_ausencia_comprovante: 0,
            sci_exclusao: 0,
            total_sci_exclusao: 0,
            reembolso_fornecedor:0,
            total_reembolso_fornecedor: 0,
            consumo_acima_esperado:0,
            total_consumo_acima_esperado: 0,



            


            statuses: [
                { label: "Inativo", value: "0" },
                { label: "Ativo", value: "1" },
            ],
        };
    },
    productService: null,
    created() {
        this.initFilters();
    },
    mounted() {
        this.listarFornecedor();
        this.listarInicial();

        //this.productService.getProducts().then((data) => (this.products = data));
    },
    watch: {
        planilhasSelecionadas(novoValor) {
            if (novoValor.length > 0) {
                this.selecionados = false;
            }
            else {
                this.selecionados = true;
            }
        }
    },

    methods: {
        aprovarSelecionados() {
            let objeto = this.planilhasSelecionadas;
            for (const prop in objeto) {
                this.aprovarPagamento(objeto[prop].id)
            }
            this.listarInicial();
        },
        reprovarSelecionados() {
            let objeto = this.planilhasSelecionadas;
            for (const prop in objeto) {
                this.aprovarPagamento(objeto[prop].id)
            }
            this.listarInicial();
        },
        mostrarPagamento(planilha) {

            this.listarRelatorio(planilha.id, planilha.id_fornecedor);
            this.fornecedor = planilha.razao_social;
            this.mes_referencia = planilha.mes_referencia
            this.planilhas_importadas = "";
            this.planilhas_validar = "";
            this.planilhas_reprovadas = "";
            this.planilhas_validadas = "";

            this.total_end_id = "";
            this.end_id_validado = "";
            this.end_id_contestado = "";
            this.valor_validado = "";
            this.valor_contestado = "";
            this.valor_total = "";

            this.medidor_proprio_tim = "";
            this.site_em_aquisicao = "";
            this.valor_total_contestado = "";
            this.ModalPlanilhasFornecedores = true;

        },
        aprovarPagamento(id) {
            this.loading = true;
            var dados = "";
            if (id) {
                dados = {
                    id_planilha: id,
                    status: 3
                }
            } else {
                dados = {
                    id_planilha: this.id_planilha,
                    status: 3
                }
            }
            this.axios
                .post("/fornecedores/aprovar-pagamento", dados)
                .then(() => {
                    this.listarRelatorioPorFornecedor();
                    this.loading = false;
                    this.ModalPlanilhasFornecedores = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },
        reprovarPagamento(id) {
            this.loading = true;
            var dados = "";
            if (id) {
                dados = {
                    id: id,
                    status: 4,
                    motivo: this.motivo
                }
            } else {
                dados = {
                    id: this.id_planilha,
                    status: 4,
                    motivo: this.motivo
                }
            }

            this.axios
                .post("/fornecedores/aprovar-pagamento", dados)
                .then(() => {
                    this.listarRelatorioPorFornecedor();
                    this.loading = false;
                    this.ModalPlanilhasFornecedores = false;
                    this.Modalreprovar = false;
                })
                .catch((error) => {
                    this.loading = false;
                    console.log(error);
                });
        },
        listarFornecedor() {
            this.axios
                .post("/fornecedores/listar")
                .then((response) => {
                    console.log(response);
                    let options = [];
                    let desc_fornecedores = {};
                    response.data.dados.map((el) => {
                        let option = { value: el["id"], label: el["razao_social"] };
                        desc_fornecedores[el["id"]] = el["razao_social"];
                        options.push(option);
                    });
                    this.fornecedores = options;
                    this.desc_fornecedores = desc_fornecedores;
                    console.log(desc_fornecedores)
                })
                .catch((error) => {
                    console.log(error);
                });
        },

        listarInicial() {
            this.axios
                .post("/relatorios/planilhas-por-fornecedor")
                .then((response) => {
                    this.planilhas_por_fornecedores = response.data.dados;
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });
        },

        listarRelatorioPorFornecedor() {
            //planilhas-por-fornecedor
            let dados = {
                id_fornecedor: this.id_fornecedor,
                mes: this.mes,
                ano: this.ano
            }


            this.axios
                .post("/relatorios/planilhas-por-fornecedor", dados)
                .then((response) => {
                    this.planilhas_por_fornecedores = response.data.dados;
                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });
        },
        listarRelatorio(id, id_fornecedor) {

            this.id_planilha = id;
            let dados = {
                id_fornecedor: id_fornecedor,
                mes: this.mes,
                ano: this.ano,
                id_planilha: id
            }


            this.axios
                .post("/relatorios/liberar-pagamentos", dados)
                .then((response) => {

                    if (response.data.dados.planilhas_validadas > 0) {
                        this.temdados = false;

                        this.planilhas_importadas = response.data.dados.planilhas_importadas;
                        this.planilhas_validar = response.data.dados.planilhas_validar;
                        this.planilhas_reprovadas = response.data.dados.planilhas_reprovadas;
                        this.planilhas_validadas = response.data.dados.planilhas_validadas;

                        this.total_end_id = response.data.dados.qtd_endid_enviado;
                        this.end_id_validado = response.data.dados.qtd_endid_aprovado;
                        this.end_id_contestado = response.data.dados.qtd_endid_reprovado;
                        this.valor_validado = response.data.dados.valor_endid_aprovado;
                        this.valor_contestado = response.data.dados.valor_endid_reprovado;
                        this.valor_total = response.data.dados.valor_total;

                        this.medidor_proprio_tim = response.data.dados.medidor_proprio_tim;
                        this.total_medidor_proprio_tim = response.data.dados.total_medidor_proprio_tim;

                        this.site_em_aquisicao = response.data.dados.site_em_aquisicao;
                        this.outros = response.data.dados.outros;
                        this.valor_total_contestado = response.data.dados.valor_total_contestado
                        this.total_site_em_aquisicao = response.data.dados.total_site_em_aquisicao
                        this.total_outros = response.data.dados.total_outros
                        
                        this.total_mes_ref_pago = response.data.dados.total_mes_ref_pago
                        this.mes_ref_pago = response.data.dados.mes_ref_pago
                        this.site_nao_localizado = response.data.dados.site_nao_localizado
                        this.total_site_nao_localizado = response.data.dados.total_site_nao_localizado
                        this.site_offgrid = response.data.dados.site_offgrid
                        this.total_site_offgrid = response.data.dados.total_site_offgrid
                        this.ausencia_comprovante = response.data.dados.ausencia_comprovante
                        this.total_ausencia_comprovante = response.data.dados.total_ausencia_comprovante
                        this.sci_exclusao = response.data.dados.sci_exclusao
                        this.total_sci_exclusao = response.data.dados.total_sci_exclusao
                        this.reembolso_fornecedo = response.data.dados.reembolso_fornecedo
                        this.total_reembolso_fornecedor = response.data.dados.total_reembolso_fornecedor
                        this.consumo_acima_esperad = response.data.dados.consumo_acima_esperad
                        this.total_consumo_acima_esperado = response.data.dados.total_consumo_acima_esperado
                    }
                    else {
                        this.temdados = true;

                        this.planilhas_importadas = response.data.dados.planilhas_importadas;
                        this.planilhas_validar = response.data.dados.planilhas_validar;
                        this.planilhas_reprovadas = response.data.dados.planilhas_reprovadas;
                        this.planilhas_validadas = response.data.dados.planilhas_validadas;

                        this.total_end_id = response.data.dados.qtd_endid_enviado;
                        this.end_id_validado = response.data.dados.qtd_endid_aprovado;
                        this.end_id_contestado = response.data.dados.qtd_endid_reprovado;
                        this.valor_validado = response.data.dados.valor_endid_aprovado;
                        this.valor_contestado = response.data.dados.valor_endid_reprovado;
                        this.valor_total = response.data.dados.valor_total;

                        this.medidor_proprio_tim = response.data.dados.medidor_proprio_tim;
                        this.site_em_aquisicao = response.data.dados.site_em_aquisicao;
                        this.valor_total_contestado = response.data.dados.valor_total_contestado
                        this.total_site_em_aquisicao = response.data.dados.total_site_em_aquisicao
                        this.total_outros = response.data.dados.total_outros
                        this.total_mes_ref_pago = response.data.dados.total_mes_ref_pago
                        this.mes_ref_pago = response.data.dados.mes_ref_pago
                        this.site_nao_localizado = response.data.dados.site_nao_localizado
                        this.total_site_nao_localizado = response.data.dados.total_site_nao_localizado
                        this.site_offgrid = response.data.dados.site_offgrid
                        this.total_site_offgrid = response.data.dados.total_site_offgrid
                        this.ausencia_comprovante = response.data.dados.ausencia_comprovante
                        this.total_ausencia_comprovante = response.data.dados.total_ausencia_comprovante
                        this.sci_exclusao = response.data.dados.sci_exclusao
                        this.total_sci_exclusao = response.data.dados.total_sci_exclusao
                        this.reembolso_fornecedo = response.data.dados.reembolso_fornecedo
                        this.total_reembolso_fornecedor = response.data.dados.total_reembolso_fornecedor
                        this.consumo_acima_esperad = response.data.dados.consumo_acima_esperad
                        this.total_consumo_acima_esperado = response.data.dados.total_consumo_acima_esperado
                    }

                })
                .catch(() => {
                    this.$toast.add({
                        severity: "error",
                        summary: "Ops!",
                        detail: "Chamar o T.I.",
                        life: 3000,
                    });
                });
        },


        findIndexById(id) {
            let index = -1;
            for (let i = 0; i < this.products.length; i++) {
                if (this.products[i].id === id) {
                    index = i;
                    break;
                }
            }
            return index;
        },
        createId() {
            let id = "";
            var chars =
                "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
            for (var i = 0; i < 5; i++) {
                id += chars.charAt(Math.floor(Math.random() * chars.length));
            }
            return id;
        },
        exportCSV() {
            this.$refs.dt.exportCSV();
        },

        initFilters() {
            this.filters = {
                global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            };
        },
    },
};
</script>
<style>
.p-datatable-thead,
.p-datatable-tbody {

    white-space: nowrap !important;

}
</style>